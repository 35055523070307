import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { ContactFormBackButton } from "./ContactFormBackButton"
import { ContactFormButton } from "./ContactFormButton"

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  width: 808px;
  margin-left: -94px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column;
    width: 100%;
    margin-left: auto;
  }
`

const BackButtonWrapper = styled.div`
  width: 320px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: 240px;
  }
`

const SubmitButtonWrapper = styled.div`
  width: 440px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: 440px;
  }
`

type Props = {
  isDisabled: boolean
}
export const ContactFormSendButton: React.VFC<Props> = ({isDisabled}) => {
  return (
    <ButtonWrapper>
      <BackButtonWrapper>
        <ContactFormBackButton
          text="入力フォームに戻る"
          onClick={
            (e) => {
              e.preventDefault();
              history.back();
            }
          }
        />
      </BackButtonWrapper>
      <SubmitButtonWrapper>
        <ContactFormButton
          text={"送信する"}
          type={"submit"}
          disabled={isDisabled}
        />
      </SubmitButtonWrapper>
    </ButtonWrapper>
  )
}