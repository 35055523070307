import React from 'react'
import styled, { css } from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

import IconTriangle from "../../../images/icon_triangle.svg"

type Props = {
  text?: string
  type?: "button" | "submit" | "reset"
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

export const ContactFormButton: React.FC<Props> = props => {

  const buttonColor = props.disabled ? "rgba(35,35,35,0.3)" : "#222222";
  const itemColor = "#FFFFFF";
  const border = "2px solid #FFFFFF";

  const ButtonWrapper = styled.button`
    position: relative;
    width: 440px;
    height: 96px;
    background: ${buttonColor};
    border: ${border};
    box-sizing: border-box;
    display:flex;
    justify-content: space-between;
    align-items: center;
    transition: .3s;
    cursor: pointer;

    ${props.disabled ?
    css`
      pointer-events: none;
    `
    :
    css`
      &:hover {
        background-color: #FFFFFF;
        border: 2px solid #222222;
      }
    `}

    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      width: 100%;
      height: 80px;
    }
  `

  const Text = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${itemColor};
    margin-left: 48px;
    transition: .3s;

    ${ButtonWrapper}:hover & {
      color: #222222;
    }

    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: 14px;
    }
  `


  const Triangle = styled.div`
    margin-right: 36px;
    mask-image: url(${IconTriangle});
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
    transition: .3s;

    ${ButtonWrapper}:hover & {
      margin-right: 28px;
      background-color: #222222;
    }

    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      margin-right: 20px;
        ${ButtonWrapper}:hover & {
          margin-right: 18px;
        }
    }
  `

  return (
    <ButtonWrapper
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <Text>{props.text}</Text>
      <Triangle />
    </ButtonWrapper>
  )
}
