import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

/**
 * Material
 */
import { ContactFlow } from "./ContactFlow"

/**
 * Element & Styles
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 96px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 0 0 80px;
  }
`
const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 48px;
`
const Title = styled.h2`
  margin: 96px 0 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: #222222;
  font-size: 32px;
  line-height: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 48px 0 0;
    font-size: 20px;
    line-height: 32px;
  }
`
const Text = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 24px;
  }
`

/**
 * Props
 */
type Props = {
  title: string
  step: 1 | 2 | 3
}

/**
 * Component
 */
export const ContactDescription: React.VFC<Props> = props => {
  let [message1, message2] = ["", ""];

  switch (props.step) {
    case 1:
      message1 = "お問い合わせ内容確認後、担当者よりご連絡させていただきます。"
      message2 = "各項目を入力していただき、入力が終わりましたらサイトポリシーに同意の上、<br />入力内容の確認ボタンを押してください。"
      break;
    case 2:
      message1 = "以下の入力内容をご確認の上、間違いがなければ「送信する」ボタンを押してください。"
      message2 = "内容を訂正する場合は「戻る」ボタンで前のページへお戻りください。"
      break;
    case 3:
      message1 = "この度はネクストライブ株式会社にお問い合わせ頂き、誠にありがとうございます。"
      message2 = "送信内容を確認の上、担当者より折り返しご連絡を差し上げますので、少々お待ちください。"
      break;
    default:
      break;
  }

  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <TextsWrapper>
        <Text dangerouslySetInnerHTML={{__html: message1}} />
        <Text dangerouslySetInnerHTML={{__html: message2}} />
      </TextsWrapper>
      <ContactFlow current={props.step} />
    </Wrapper>
  )
}
