import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`

const LabelWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const Label = styled.span`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Value = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #222222;
  word-break: break-word;
  white-space: pre-wrap;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 24px;
  }
`

const Hidden = styled.input`
`

type Props = {
  label: String
  values: Array<any>
}

const generateHidden = (label, values) => {
  if (values && values.length > 0) {
    if (values[0].files) {
      return (
        <Hidden type="hidden" name={`${label}`} value={values.map((value) => value.files[0].name).join(`\n`)} />
      )
    } else {
      return (
        <Hidden type="hidden" name={`${label}`} value={values.join(`\n`)} />
      )
    }
  } else {
    return (
      <Hidden type="hidden" name={`${label}`} value={""} />
    )
  }
}

export const ContactInputGroupConfirm: React.VFC<Props> = ({label, values}) => {

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>{label}</Label>
      </LabelWrapper>
      <ValuesWrapper>
        {
          values && values.map((value, i) => {
            if (value.files) {
              return(
                <Value key={`${label}-${i}`}>{value.files[0].name}</Value>
              )
            } else {
                return (
              <Value key={`${label}-${i}`}>{value}</Value>
                )
            }
          })
        }
        {
          generateHidden(label, values)
        }
      </ValuesWrapper>
    </Wrapper>
  )
}
