import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../../../template/Layout"
import {
  DATE_TYPE,
} from "../../../AppConst"

/**
 * Material
 */
import Head from "../../../head"
import { SectionTitle } from "../../common/SectionTitle"
import { ContactContainer } from "../common/ContactContainer"
import { ContactDescription } from "../common/ContactDescription"
import { ContactAskDetail } from "./ContactAskDetail"
import { Props as BreadcrumbsData } from "../../common/Breadcrumbs"
import { useCasualVisitFormContext } from "../../../utlis/FormValues"
import { invalidRequired } from "../../../utlis/check"

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

/**
 * Component
 */
export const AskConfirm: React.VFC<Props> = props => {
  const { state } = useCasualVisitFormContext()

  useEffect(() => {
    if (invalidRequired(state.name)
      || invalidRequired(state.email)
      || invalidRequired(state.methods)
      || invalidRequired(state.dateType)
      ||
      (
      state.dateType === DATE_TYPE[1]
      && (
        invalidRequired(state.date1)
        || invalidRequired(state.date2)
        || invalidRequired(state.date3)
        || invalidRequired(state.dateType1)
        || invalidRequired(state.dateType2)
        || invalidRequired(state.dateType3)
      )
    )
    ) {
      navigate("../")
    }
  }, [])

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head />
      <ContactContainer>
        <SectionTitle title='CONTACT' subTitle='お問い合わせ' />
        <ContactDescription title="カジュアル面談のお申し込み" step={2} />
        <ContactAskDetail
          name={state.name || ""}
          age={state.age || ""}
          email={state.email || ""}
          dateType={state.dateType || ""}
          date1={state.date1 || ""}
          dateType1={state.dateType1 || ""}
          date2={state.date2 || ""}
          dateType2={state.dateType2 || ""}
          date3={state.date3 || ""}
          dateType3={state.dateType3 || ""}
          methods={state.methods || ""}
          ask={state.ask || ""}
        />
      </ContactContainer>
    </Layout>
  )
}
