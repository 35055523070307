import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  DATE_TYPE,
} from "../../../AppConst"
import { ContactInputGroupConfirm } from "../common/ContactInputGroupConfirm"
import { ContactFormSendButton } from "../common/ContactFormSendButton"
import { contact } from "../../../data/contact"

/**
 * Element & Styles
 */
const Form = styled.form`
`

const FormNode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const HiddenItem = styled.input`
`

/**
 * Props
 */
type State = {
  name: string
  email: string
  methods: string
}

type Options = {
  age: string
  dateType: string
  date1: string
  dateType1: string
  date2: string
  dateType2: string
  date3: string
  dateType3: string
  ask: string
}

type Props = State & Partial<Options>

/**
 * Component
 */
export const ContactAskDetail: React.VFC<Props> = props => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [waitingLoad, setWaitingLoad] = useState(true);

  useEffect(() => {
    setWaitingLoad(false);
  }, [])

  return (
    <Form
      name="カジュアル面談のお申し込み"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
      action="/contact/casualvisit/accepted"
      onSubmit={() => {
        setIsDisabled(true)
      }}
    >
      <FormNode>
        <HiddenItem type='hidden' name='bot-field' />
        <HiddenItem type='hidden' name='form-name' value='カジュアル面談のお申し込み' />

        <ContactInputGroupConfirm
          label={contact.casualvisit.name.title}
          values={[props.name]}
        />
        <ContactInputGroupConfirm
          label={contact.casualvisit.age.title}
          values={[props.age]}
        />
        <ContactInputGroupConfirm
          label={contact.casualvisit.email.title}
          values={[props.email]}
        />
        <ContactInputGroupConfirm
          label={contact.casualvisit.dateType.title}
          values={[props.dateType]}
        />
        {
          (waitingLoad || props.dateType === DATE_TYPE[1])
          &&
          <>
            <ContactInputGroupConfirm
              label={contact.casualvisit.date1.title}
              values={[props.date1, props.dateType1]}
            />
            <ContactInputGroupConfirm
              label={contact.casualvisit.date2.title}
              values={[props.date2, props.dateType2]}
            />
            <ContactInputGroupConfirm
              label={contact.casualvisit.date3.title}
              values={[props.date3, props.dateType3]}
            />
          </>
        }
        <ContactInputGroupConfirm
          label={contact.casualvisit.methods.title}
          values={[props.methods]}
        />
        <ContactInputGroupConfirm
          label={contact.casualvisit.ask.title}
          values={[props.ask]}
        />

        <ContactFormSendButton isDisabled={isDisabled} />
      </FormNode>
    </Form>
  )
}
