import React from 'react'
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

import { TriangleSwitch } from "../../../components/common/TriangleSwitch"
import IconTriangle from "../../../images/icon_triangle.svg"

type Props = {
  text?: string
  type?: "button" | "submit" | "reset"
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const ContactFormBackButton: React.FC<Props> = props => {

  const ButtonWrapper = styled.button`
    position: relative;
    width: 320px;
    height: 96px;
    background: #222222;
    border: none;
    box-sizing: border-box;
    display:flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 1px 6px;
    border: 2px solid #FFFFFF;
    transition: .3s;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    &:hover {
      background: #FFFFFF;
      border: 2px solid #222222;
    }
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      width: 240px;
      height: 80px;
    }
  `

  const Text = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-right: 48px;
    ${ButtonWrapper}:hover & {
      color: #222222;
    }
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: 14px;
    }
  `

  const Triangle = styled.div`
    transform:rotate(180deg);
    margin-left: 36px;
    mask-image: url(${IconTriangle});
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
    transition: .3s;
    ${ButtonWrapper}:hover & {
      margin-left: 28px;
      background-color: #222222;
    }
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      margin-left: 20px;
      ${ButtonWrapper}:hover & {
        margin-left: 18px;
      }
    }
  `
  return (
    <div>
      <ButtonWrapper
        type={props.type}
        onClick={props.onClick}
      >
      <Triangle />
      <Text>{props.text}</Text>
    </ButtonWrapper>
    </div>
  )
}
