// 半角英数記号かどうか判定
export const isSingleByteAlphanumericSymbol = (value: string) => {
  if(!value) return false;
  return new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/).test(value);
}

/**
 * 必須項目の値でエラーがないかどうか判定する
 * @param value 値
 * @returns true: エラーがない / false: エラーがある
 */
export const validRequired = (value: any) => {
  return !invalidRequired(value);
}

/**
 * 必須項目の値でエラーがあるかどうか判定する
 * @param value 値
 * @returns true: エラーがある / false: エラーがない
 */
export const invalidRequired = (value: any) => {

  // 存在しないときは、エラーとする
  if(!value) return true;

  switch (value.constructor) {
    case String:
      return !value.trim()
    case Array:
      return value.length === 0;
    default:
      return false;
  }

}