import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../../AppConst"
import { SpWrapper } from "../../common/SpWrapper"

const Container = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  box-sizing: border-box;
  max-width: 640px;
  padding-bottom: 160px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: none;
    padding-bottom: 80px;
  }
`

type Props = {
  children: any
}
export const ContactContainer: React.VFC<Props> = ({children}) => {
  return (
    <Container>
      <SpWrapper>
        {children}
      </SpWrapper>
    </Container>
  )
}
