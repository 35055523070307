export const contact = {
  service: {
    types: {
      title: "お問い合わせの種類",
      required: true,
    },
    content: {
      title: "お問い合わせ内容",
      required: true,
    },
    company: {
      title: "会社名",
      required: false,
    },
    name: {
      title: "氏名",
      required: true,
    },
    email: {
      title: "メールアドレス",
      required: true,
    },
    webSites: {
      title: "WEBサイト",
      required: false,
    },
    files: {
      title: "添付ファイル",
      required: false,
      annotaion: "※1MBのファイル2つまで",
    }
  },
  recruit: {
    name: {
      title: "氏名",
      required: true,
    },
    email: {
      title: "メールアドレス",
      required: true,
    },
    resumeFiles: {
      title: "履歴書（添付）",
      required: false,
      annotaion: "※1MBのファイル2つまで",
    },
    workFiles: {
      title: "職務経歴書（添付）",
      required: false,
      annotaion: "※1MBのファイル2つまで",
    },
    webSites: {
      title: "WEBサイト",
      required: false,
    },
    point: {
      title: "当社に興味を持っていただいたポイントを教えてください",
      required: false,
    },
    other: {
      title: "その他ご質問等",
      required: false,
    },
  },
  casualvisit: {
    name: {
      title: "氏名",
      required: true,
    },
    age: {
      title: "年齢",
      required: false,
    },
    email: {
      title: "メールアドレス",
      required: true,
    },
    dateType: {
      title: "希望日時",
      required: true,
    },
    date1: {
      title: "第一候補",
      required: true,
    },
    dateType1: {
      title: "",
      required: true,
    },
    date2: {
      title: "第二候補",
      required: true,
    },
    dateType2: {
      title: "",
      required: true,
    },
    date3: {
      title: "第三候補",
      required: true,
    },
    dateType3: {
      title: "",
      required: true,
    },
    methods: {
      title: "カジュアル面談の方法",
      required: true,
      annotation: "※オンラインでのカジュアル面談はGoogle Meetを使用します。",
    },
    ask: {
      title: "聞きたいこと",
      required: false,
      message: "事前に聞いてみたいことがあれば、ご自由にこちらへお書き下さい。",
    }
  },
  etc: {
    types: {
      title: "お問い合わせの種類",
      required: true,
    },
    content: {
      title: "お問い合わせ内容",
      required: true,
    },
    company: {
      title: "会社名",
      required: false,
    },
    name: {
      title: "氏名",
      required: true,
    },
    email: {
      title: "メールアドレス",
      required: true,
    },
    tel: {
      title: "電話番号",
      required: false,
    },
    address: {
      title: "所在地",
      required: false,
    },
    webSites: {
      title: "WEBサイト",
      required: false,
    },
    files: {
      title: "添付ファイル",
      required: false,
      annotaion: "※1MBのファイル2つまで",
    },
  }
}
