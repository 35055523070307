import React from "react"
import styled from "styled-components"
import {
  RESPONSIVE_STYLES,
} from "../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  width: 100%;
  max-width: 432px;
  margin: auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 208px;
  }
`

const Circles = styled.div<{current: number}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 8px;
    background: linear-gradient(
      90deg,
      #61B1C1 0%,
      #61B1C1 ${props => props.current === 1 ? '0%' : props.current === 2 ? '50%' : '100%'},
      #EFEFEF ${props => props.current === 1 ? '0%' : props.current === 2 ? '50%' : '100%'},
      #EFEFEF 100%
    );
  }
`

const CircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`

const Circle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: #EFEFEF;
  color: #AAAAAA;
  &[data-is-current='true'] {
    background: #61B1C1;
    color: #222222;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
`

const Number = styled.span`
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Titles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.span`
  width: 80px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #AAAAAA;
  &[data-is-current='true'] {
    color: #222222;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 48px;
    font-size: 14px;
    line-height: 16px;
  }
`

type Props = {
  current?: number
}

/**
 * 問い合わせ画面 共通 フローコンポーネント
 * @param current 1:入力 2:確認 3:完了
 * @returns ContactFlow
 */
export const ContactFlow: React.VFC<Props> = ({current = 1}) => {
  return (
  <Wrapper>
    <Circles current={current}>
      <CircleWrapper>
        <Circle data-is-current={current >= 1}>
          <Number>1</Number>
        </Circle>
      </CircleWrapper>
      <CircleWrapper>
        <Circle data-is-current={current >= 2}>
          <Number>2</Number>
        </Circle>
      </CircleWrapper>
      <CircleWrapper>
        <Circle data-is-current={current >= 3}>
          <Number>3</Number>
        </Circle>
      </CircleWrapper>
    </Circles>
    <Titles>
      <Title data-is-current={current >= 1}>入力</Title>
      <Title data-is-current={current >= 2}>確認</Title>
      <Title data-is-current={current >= 3}>完了</Title>
    </Titles>
  </Wrapper>
  )
}
